import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 38px;
  height: 45px;
  color: ${ ({ theme }) => theme.colors.mainTextWhite };
  background-color: ${ ({ theme }) => theme.colors.mainPurple };
  font-family: ${ ({ theme }) => theme.fonts.headerFont };
  font-weight: 600;
  font-size: 14px;
  border-radius: 23px;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  @media(min-width:1200px){
    font-size: 18px;
    line-height: 22px;
    justify-content: center;
    margin: 0;
  }
  &.btn-light {
    background-color: ${ ({ theme }) => theme.colors.mainButtonLightBlue };
    color: ${ ({ theme }) => theme.colors.mainNavyBlue };
    justify-content: center;
  }
  &.btn-right {
    margin-right: 30px;
  }
  &.btn-center {
    margin: 0 auto;
    display: flex;
  }  
  &.btn-casestudiesbottom {
    margin: 0 auto;
    display: block;
    background-color: ${ ({ theme }) => theme.colors.mainTextLightBlue };
    color: ${ ({ theme }) => theme.colors.componentsButtonNavy }; 
  }
  &.btn-case-studies-hide {
    display: none;
  }
  &.btn-casestudies {
    margin: 0 auto;
    display: ${ ({ latest }) => latest ? "block" : "none" };
    color: ${ ({ theme }) => theme.colors.mainTextLightBlue };
    padding: 0 28px;
    @media(min-width: 768px){
      padding: 0 38px;}
    }
  &.btn-components {
    background-color: ${ ({ theme }) => theme.colors.mainWhite }; !important
    color: ${ ({ theme }) => theme.colors.componentsButtonNavy };
    font-weight: bold;
    padding: 0 34px;
    height: 30px;
    @media(min-width: 410px){
      padding: 20px 34px;
    }
    }
  &.btn-about-us {
    background-color: ${({ theme }) => theme.colors.mainButtonLightBlue} !important;
    color: ${({ theme }) => theme.colors.mainNavyBlue};
    font-weight: bold;
    padding: 0 34px;
    @media(min-width: 410px){
      padding: 0px 34px;
    } 
  }
  &.btn-getstarted {
    margin: 0 auto;
    width: 150px; 
    text-align: center;
    @media(min-width: 1200px){
      margin-left: 0;
      width: 200px; 
    }
  }
  &.btn-worksteps {
    margin-left: 0;
    //max-width: 50%; 
    padding: 0 30px; 
    text-align: center;
    @media(min-width: 768px){
      padding: 0 38px; 
    }
  }
  &.btn-left {
    display: ${({latest}) => latest ? 'flex' : 'none'};
    margin-left: 0;
  }
`

const BasicButton = (props) => {
  const { children, href, to } = props
  return (
    <>
      { href && <StyledButton { ...props } href={ href } as={ "a" }>{ children }</StyledButton> }
      { to && <StyledButton { ...props } as={ Link }>{ children }</StyledButton> }
      { !href && !to && <StyledButton { ...props }>{ children }</StyledButton> }
    </>
  )
}

export default BasicButton
