import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import SEO from "../components/seo"
import BasicButton from "../../src/components/common/buttons/basicButton"
import PageContainer from "../components/pageContainer"
import { graphql, Link } from "gatsby"
import { setTopColors, toggleSubmenu } from "../state/app"
import { connect } from "react-redux"
import getHtmlFromRedactorField from "../utils/getHtmlFromRedactorField"
import { saveUrlsToLocalStorage } from "../components/common/type-to-url-match/type-to-url-match"

const GlobalStyles = createGlobalStyle`
	body {
	  background-color: ${({ theme }) => theme.colors.mainPurple} !important;
  }
`

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
`

const HeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 20px auto;
	@media(min-width: 768px){
		margin: 50px auto;
	}
`

const StyledHeader = styled.div`
	font-size: 20px;
	font-weight: bold;
	font-family: ${({ theme }) => theme.fonts.headerFont};
  color: ${({ theme }) => theme.colors.mainNavyBlue};
  @media(min-width: 768px){
    font-size: 40px;
  }
  span{ 
    font-family: ${({ theme }) => theme.fonts.headerFont};
    &.archee-navy-blue{
      color: ${({ theme }) => theme.colors.mainTextNavyBlue};
  }
    &.archee-light-blue{
      color: ${({ theme }) => theme.colors.mainTextLightBlue};
  }
    &.archee-white{
      color: ${({ theme }) => theme.colors.mainTextWhite};
  }
    &.archee-dark-grey{
      color: ${({ theme }) => theme.colors.mainTextDarkGrey};
  }
}
`

const StyledImageCopy = styled.img`
	width: 100%;
	margin: 0 auto;
`

const ImageWrapper = styled.div`
	width: 300px;
	height: 240px;
	box-sizing: border-box;
	margin: 0 auto -20px ;
	padding-right: 25px;
	@media(min-width: 768px){
		width: 600px;
		height: 430px;
		padding-right: 50px;
		margin-bottom: 0;
	}
`

class NotFoundPage extends React.Component {

  componentDidMount() {
    const { dispatch, pageContext } = this.props
    dispatch(setTopColors("fiolety"))
    dispatch(toggleSubmenu(false))
    saveUrlsToLocalStorage(pageContext)
  }

  render() {

    const { data } = this.props
    const entries = data.error.nodes?.[0] || ""
    const entry404 = entries.archee404?.[0] || []
    const PhotoUrl = entry404.ilustracja?.[0].url
    const ButtonText = entry404.przyciskTekst
    const Header404 = entry404.naglowek
    return (
      <>
        <GlobalStyles/>
        <PageContainer>
          <StyledWrapper>
            <ImageWrapper>
              <StyledImageCopy src={PhotoUrl}/>
            </ImageWrapper>
            <HeaderWrapper>
              <StyledHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(Header404)}/>
            </HeaderWrapper>
            <BasicButton as={Link} className="btn-light btn-center">{ButtonText}</BasicButton>
          </StyledWrapper>
        </PageContainer>
      </>
    )
  }
}

export default connect(state => ({
  topColors: state.app.topColors,
  isSubmenuVisible: state.app.isSubmenuVisible,
}), null)(NotFoundPage)

export const query = graphql`
    query($remoteId: ID, $langKey: String) {
            error: allCraftArchee404Archee404Entry(filter: { remoteId: {eq: $remoteId}, language: {eq: $langKey}}) {
                nodes{
                    archee404 {
                        ... on Craft_archee404_podstrona404_BlockType {
                            ilustracja{
                                url
                            }
                            naglowek
                            przyciskTekst
                        }
                    }
                }
            }
    }`

export function Head() {
    return (
        <SEO seoData={{ seoTitle: '404' }}/>
    )
}